<template>
  <BasePage>
    <div class="wrapper">
      <v-img
        class="indicator-logo"
        max-height="150"
        max-width="200"
        src="../../assets/images/formbg_logo.png"
      />
      <Container>
        <div
          class="  content py-15"
          v-if="questions.length > 0 && !loading && !showResult"
        >
          <div style="position:relative; paddingTop:20px">
            <!-- d-flex flex-column align-center justify-space-between -->
            <div v-if="currentQuestionIndex < questions.length" class="">
              <div class="counter-info">
                <v-btn
                  @click="indicatorNotes = true"
                  class="mx-5"
                  style="height:70px"
                  text
                >
                  <div class="d-flex flex-column align-center ">
                    <v-icon color="primary" size="30">mdi-information</v-icon>
                    <div class="body-1 primary--text mt-1">
                      {{ $t('instructions') }}
                    </div>
                  </div>
                </v-btn>
                <span
                  class="font-weight-bold rounded-circle text-center counter selected counter-info2"
                  >{{ currentQuestionIndex + 1 + '/' + questions.length }}
                </span>
              </div>
              <template v-if="questions[currentQuestionIndex].video_link">
                <!-- <video width="100%" height="680" controls>
                <source
                  :src="questions[currentQuestionIndex].video_link"
                  type="video/mp4"
                />
              </video> -->

                <iframe
                  v-if="!readyToAnswer"
                  :src="questions[currentQuestionIndex].video_link"
                  frameborder="0"
                  width="100%"
                  height="500"
                ></iframe>
                <v-btn
                  @click="readyToAnswerHandler"
                  class="my-5"
                  color="secondary"
                  elevation="0"
                  outlined
                  >{{ !readyToAnswer ? $t('readyToAnswer') : $t('watchAgain') }}
                  <v-icon class="mx-1">
                    {{
                      !readyToAnswer
                        ? $root.lang == 'ar'
                          ? 'mdi-arrow-left'
                          : 'mdi-arrow-right'
                        : 'mdi-reload'
                    }}</v-icon
                  >
                </v-btn>
              </template>
              <template v-else>
                <div
                  class="text-center"
                  style="width:100%"
                  v-html="questions[currentQuestionIndex][lang + 'title']"
                />
              </template>
              <!-- start group -->
              <v-row
                v-if="
                  (questions[currentQuestionIndex].video_link &&
                    readyToAnswer) ||
                    !questions[currentQuestionIndex].video_link
                "
                class="my-3"
              >
                <v-col
                  cols="6"
                  v-for="(ans, idx) in questions[currentQuestionIndex]
                    .answer_id"
                  :key="idx"
                  class="d-flex justify-center"
                >
                  <div
                    :class="
                      answers[currentQuestionIndex] == ans.value.value &&
                        'selected'
                    "
                    class="py-5 rounded-lg px-10"
                  >
                    <div v-if="ans.image && readyToAnswer" dir="ltr">
                      <v-img dir="ltr" :src="ans.image.data.full_url"></v-img>
                    </div>
                    <div v-else class="ansp" v-html="ans[lang + 'title']" />

                    <div class="d-flex flex-column align-center mt-6">
                      <v-btn
                        icon
                        link
                        :class="
                          answers[currentQuestionIndex] == ans.value.value
                            ? 'success'
                            : 'error lighten-3'
                        "
                        class="white--text pa-5 ma-5"
                        @click="
                          setAnswer(
                            questions[currentQuestionIndex].questionsCount,
                            currentQuestionIndex,
                            ans.value.value
                          )
                        "
                      >
                        <v-icon size="35">mdi-check</v-icon>
                      </v-btn>
                      <!-- <div class="mt-5" v-if="ans[lang + 'readmore']">
                        <v-btn
                          color="secondary"
                          text
                          @click="knowMoreHandler(ans[lang + 'readmore'])"
                        >
                          {{ $t('knowMore') }}
                          <v-icon size="18">mdi-exclamation-thick</v-icon>
                        </v-btn>
                      </div> -->
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="mt-5 d-flex justify-center"
                  v-if="questions[currentQuestionIndex][lang + 'readmore']"
                >
                  <div>
                    <v-btn
                      color="secondary"
                      text
                      @click="
                        knowMoreHandler(
                          questions[currentQuestionIndex][lang + 'readmore']
                        )
                      "
                    >
                      {{ $t('knowMore') }}
                      <v-icon size="18">mdi-exclamation-thick</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- end group -->
            </div>
          </div>
          <!-- controls -->
          <div
            class="d-flex flex-column align-center justify-center mt-10"
            style="width: 100%"
            dir="ltr"
          >
            <div
              style="width: 280px;"
              class="mx-auto d-flex justify-space-between"
            >
              <v-btn
                :disabled="currentQuestionIndex <= 0"
                color="primary"
                @click="moveQuestion('prev')"
                style="width: 120px"
              >
                <v-icon>mdi-chevron-left</v-icon>
                <div class="body-1 white--text">{{ $t('prev') }}</div>
              </v-btn>

              <v-btn
                :disabled="
                  currentQuestionIndex >= questions.length - 1 ||
                    answers.length <= currentQuestionIndex
                "
                color="primary"
                @click="moveQuestion('next')"
                style="width: 120px"
              >
                <!-- small
                fab -->

                <div class="body-1 white--text">{{ $t('next') }}</div>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <div class="mt-15" v-if="result">
              <v-btn
                color="secondary"
                style="width: 100px"
                outlined
                @click="beforeConfirmation"
              >
                <!-- @click="confirmResultMsg = true" -->
                {{ $t('send') }}
              </v-btn>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <Dialog
      v-if="(intro && indicatorNotes) || confirmResultMsg"
      :dialog="indicatorNotes || confirmResultMsg"
      @closeDialog="closeDialog"
      :sendResult="confirmResultMsg && confirmResult"
      :title="indicatorNotes ? 'notesBeforeIndicator' : 'warning'"
      :closeTxt="indicatorNotes && 'closeTxt'"
    >
      <div v-if="indicatorNotes">
        <iframe
          v-if="intro.intro_video_link"
          :src="intro.intro_video_link"
          width="100%"
          height="400px"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div v-else v-html="intro[$root.lang + 'intro_info']"></div>
      </div>
      <div v-else>
        <div v-if="confirmResultMsg && quizResult[lang + 'before_result']">
          <div v-html="quizResult[lang + 'before_result']" />
          <v-divider class="my-4" />
        </div>
        <div
          v-if="confirmResultMsg && intro[$root.lang + 'end_info']"
          v-html="intro[$root.lang + 'end_info']"
        />

        <span v-else-if="confirmResultMsg" class="black--text">
          {{ $t('indicatorConfirmMsg') }}
        </span>
      </div>
    </Dialog>
    <Dialog
      v-if="moreAns && true"
      :dialog="moreAns && true"
      @closeDialog="closeDialog"
    >
      <div>
        <div v-html="moreAns" />
      </div>
    </Dialog>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  props: ['course_id', 'enrollment_id'],
  components: {
    Dialog: () => import('@/components/Dialog'),
  },
  data() {
    return {
      loading: false,
      showResult: false,
      currentQuestionIndex: 0,
      questions: [],
      answers: [],
      validAnswers: [],
      // result: 'E-F-J-N',
      result: null,
      readyToAnswer: false,
      lang: this.$root.lang == 'en' ? 'en' : '',
      indicatorNotes: false,
      confirmResultMsg: false,
      validGroups: [],
      questionsAvg: null,
      intro: null,
      quizResult: null,
      // moreAboutQuestion: false,
      moreAns: null,
    };
  },
  methods: {
    knowMoreHandler(moreAns) {
      // this.moreAboutQuestion = true;
      this.moreAns = moreAns;
    },

    closeDialog() {
      this.indicatorNotes = false;
      this.confirmResultMsg = false;
      this.moreAns = null;
    },

    async beforeConfirmation() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/result_options?fields=*&filter[result]=${this.result}&filter[course_id]=${this.course_id}`
        );

        this.quizResult = data.data[0];
        this.confirmResultMsg = true;

        console.log('data', data.data[0]);

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async confirmResult() {
      try {
        this.loading = true;
        // const { data } = await ApiService.get(
        //   `items/result_options?fields=*&filter[result]=${this.result}&filter[course_id]=${this.course_id}`
        // );

        if (this.quizResult && this.enrollment_id) {
          await ApiService.update(`items/enrollment`, this.enrollment_id, {
            result_option_id: this.quizResult.id,
          });
        }

        this.loading = false;
        let path = this.enrollment_id
          ? `/result/${this.enrollment_id}`
          : `/result?admin_result=${this.result}&course_id=${this.course_id}`;
        this.$router.push({ path });
      } catch (error) {
        console.log(error);
      }
    },
    setAnswer(questionsCount, idx, ans) {
      if (this.answers.length - 1 >= idx) {
        if (this.answers[idx] !== 'temp') {
          this.answers = [
            ...this.answers.slice(0, idx),
            (this.answers[idx] = ans),
            ...this.answers.slice(idx + 1, this.answers.length),
          ];
        }
      } else {
        this.answers.push(ans);
        if (questionsCount == this.questionsAvg * 2)
          this.validAnswers.push(ans);
        else this.validAnswers.push('temp');
      }

      if (this.answers.length >= this.questions.length) {
        this.calcResult();
      }
    },
    async prepareGroups() {
      this.loading = true;
      const { data } = await ApiService.get(
        `items/groups?fields=id,title,question_id.title&filter[course_id]=${this.course_id}`
      );

      // get num of questions in each group
      let questionsCountInEachGroup = [];
      data.data.forEach((item, idx) => {
        questionsCountInEachGroup[idx] = item.question_id.length;
      });

      let maxIdx = 0;
      let count = (arr, item) => arr.filter((x) => x === item).length;

      questionsCountInEachGroup.forEach((item, idx) => {
        if (
          count(questionsCountInEachGroup, item) >
          count(questionsCountInEachGroup, questionsCountInEachGroup[maxIdx])
        )
          maxIdx = idx;
      });

      this.questionsAvg = questionsCountInEachGroup[maxIdx] / 2;

      this.loading = false;
    },
    async calcResult() {
      try {
        let x = [];

        const valuesCount = this.count_duplicate(
          this.validAnswers.filter((x) => x !== 'temp')
        );
        // console.log('valuesCount', valuesCount);
        // const valuesCount = this.count_duplicate(arr);
        Object.keys(valuesCount).forEach((value) => {
          valuesCount[value] > this.questionsAvg && x.push(value);
        });

        this.result = x
          .sort()
          .join()
          .replace(/,/g, '-');

        // await ApiService.post(
        //   `items/enrollment?fields=id,title,question_id.title&filter[course_id]=${this.course_id}`, {}
        // );

        // console.log(this.result);
        // this.loading = false;
      } catch (error) {
        console.log(error);
      }

      // let arr = [...this.answers];
      // this.result = arr
      //   .sort()
      //   .join()
      //   .replace(/,/g, '-');
    },

    count_duplicate(a) {
      let counts = {};
      let obj = {};

      // calc num of letters in the result array
      for (let i = 0; i < a.length; i++) {
        if (counts[a[i]]) {
          counts[a[i]] += 1;
        } else {
          counts[a[i]] = 1;
        }
      }

      //
      for (let prop in counts) {
        if (counts[prop] > this.questionsAvg) {
          obj[prop] = counts[prop];
        }
      }
      return obj;
    },

    async getQuestions() {
      try {
        this.loading = true;

        // check if the current user is not an admin && get the indicator result => if exists redirect user
        if (this.enrollment_id) {
          const enrollment = await ApiService.get(
            `items/enrollment/${this.enrollment_id}?fields=result_option_id.id`
          );

          if (enrollment.data.data.result_option_id) {
            return this.$router.go(-1);
          }
        }

        const { data } = await ApiService.get(
          `custom/indicators?id=${this.course_id}&sort=order`
        );
        this.questions = data.data;

        if (data[this.$root.lang + 'intro_info'] || data.intro_video_link) {
          this.indicatorNotes = true;
        }
        this.intro = data;

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    moveQuestion(dir) {
      this.readyToAnswer = false;
      dir == 'next' ? ++this.currentQuestionIndex : --this.currentQuestionIndex;
      setTimeout(() => {
        this.$vuetify.goTo(0);
        console.log('done');
      }, 200);
    },

    readyToAnswerHandler() {
      this.readyToAnswer = !this.readyToAnswer;
      setTimeout(() => {
        this.$vuetify.goTo(0);
        console.log('done');
      }, 200);
    },
    // nextQuestion() {
    //   this.readyToAnswer = false;
    //   ++this.currentQuestionIndex;
    // },
    // prevQuestion() {
    //   this.readyToAnswer = false;
    //   --this.currentQuestionIndex;
    // },
  },
  computed: {},
  // watch: {
  //   currentQuestionIndex: {
  //     handler() {
  //       setTimeout(() => {
  //         this.$vuetify.goTo(0);

  //         console.log('done');
  //       }, 200);
  //       // console.log('watch', this.currentQuestionIndex > this.questions.length - 1);
  //     },
  //   },
  // },
  mounted() {
    this.getQuestions();
    this.prepareGroups();
  },
};
</script>

<style scoped>
.wrapper {
  /* background-image: url('../../assets/images/formbg_13.png'); */
  width: 100%;
  height: 100%;
  /* background-size: cover; */
  font-size: 18px;
}

.content {
  min-height: calc(100vh - 64px);
  width: 100%;
}

.counter-info {
  position: absolute;
  top: -60px;
  left: 0;
}

.counter {
  width: 50px;
  height: 50px;
  line-height: 55px;
}

.wrapper .indicator-logo {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10;
}

.selected {
  background: rgba(0, 0, 0, 0.01);
  box-shadow: 0.1px 0.1px 20px rgba(0, 0, 0, 0.2);
}
</style>
