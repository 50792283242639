var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePage',[_c('div',{staticClass:"wrapper"},[_c('v-img',{staticClass:"indicator-logo",attrs:{"max-height":"150","max-width":"200","src":require("../../assets/images/formbg_logo.png")}}),_c('Container',[(_vm.questions.length > 0 && !_vm.loading && !_vm.showResult)?_c('div',{staticClass:"  content py-15"},[_c('div',{staticStyle:{"position":"relative","paddingTop":"20px"}},[(_vm.currentQuestionIndex < _vm.questions.length)?_c('div',{},[_c('div',{staticClass:"counter-info"},[_c('v-btn',{staticClass:"mx-5",staticStyle:{"height":"70px"},attrs:{"text":""},on:{"click":function($event){_vm.indicatorNotes = true}}},[_c('div',{staticClass:"d-flex flex-column align-center "},[_c('v-icon',{attrs:{"color":"primary","size":"30"}},[_vm._v("mdi-information")]),_c('div',{staticClass:"body-1 primary--text mt-1"},[_vm._v(" "+_vm._s(_vm.$t('instructions'))+" ")])],1)]),_c('span',{staticClass:"font-weight-bold rounded-circle text-center counter selected counter-info2"},[_vm._v(_vm._s(_vm.currentQuestionIndex + 1 + '/' + _vm.questions.length)+" ")])],1),(_vm.questions[_vm.currentQuestionIndex].video_link)?[(!_vm.readyToAnswer)?_c('iframe',{attrs:{"src":_vm.questions[_vm.currentQuestionIndex].video_link,"frameborder":"0","width":"100%","height":"500"}}):_vm._e(),_c('v-btn',{staticClass:"my-5",attrs:{"color":"secondary","elevation":"0","outlined":""},on:{"click":_vm.readyToAnswerHandler}},[_vm._v(_vm._s(!_vm.readyToAnswer ? _vm.$t('readyToAnswer') : _vm.$t('watchAgain'))+" "),_c('v-icon',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(!_vm.readyToAnswer ? _vm.$root.lang == 'ar' ? 'mdi-arrow-left' : 'mdi-arrow-right' : 'mdi-reload'))])],1)]:[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"},domProps:{"innerHTML":_vm._s(_vm.questions[_vm.currentQuestionIndex][_vm.lang + 'title'])}})],(
                (_vm.questions[_vm.currentQuestionIndex].video_link &&
                  _vm.readyToAnswer) ||
                  !_vm.questions[_vm.currentQuestionIndex].video_link
              )?_c('v-row',{staticClass:"my-3"},[_vm._l((_vm.questions[_vm.currentQuestionIndex]
                  .answer_id),function(ans,idx){return _c('v-col',{key:idx,staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"py-5 rounded-lg px-10",class:_vm.answers[_vm.currentQuestionIndex] == ans.value.value &&
                      'selected'},[(ans.image && _vm.readyToAnswer)?_c('div',{attrs:{"dir":"ltr"}},[_c('v-img',{attrs:{"dir":"ltr","src":ans.image.data.full_url}})],1):_c('div',{staticClass:"ansp",domProps:{"innerHTML":_vm._s(ans[_vm.lang + 'title'])}}),_c('div',{staticClass:"d-flex flex-column align-center mt-6"},[_c('v-btn',{staticClass:"white--text pa-5 ma-5",class:_vm.answers[_vm.currentQuestionIndex] == ans.value.value
                          ? 'success'
                          : 'error lighten-3',attrs:{"icon":"","link":""},on:{"click":function($event){return _vm.setAnswer(
                          _vm.questions[_vm.currentQuestionIndex].questionsCount,
                          _vm.currentQuestionIndex,
                          ans.value.value
                        )}}},[_c('v-icon',{attrs:{"size":"35"}},[_vm._v("mdi-check")])],1)],1)])])}),(_vm.questions[_vm.currentQuestionIndex][_vm.lang + 'readmore'])?_c('v-col',{staticClass:"mt-5 d-flex justify-center",attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.knowMoreHandler(
                        _vm.questions[_vm.currentQuestionIndex][_vm.lang + 'readmore']
                      )}}},[_vm._v(" "+_vm._s(_vm.$t('knowMore'))+" "),_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-exclamation-thick")])],1)],1)]):_vm._e()],2):_vm._e()],2):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center justify-center mt-10",staticStyle:{"width":"100%"},attrs:{"dir":"ltr"}},[_c('div',{staticClass:"mx-auto d-flex justify-space-between",staticStyle:{"width":"280px"}},[_c('v-btn',{staticStyle:{"width":"120px"},attrs:{"disabled":_vm.currentQuestionIndex <= 0,"color":"primary"},on:{"click":function($event){return _vm.moveQuestion('prev')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_c('div',{staticClass:"body-1 white--text"},[_vm._v(_vm._s(_vm.$t('prev')))])],1),_c('v-btn',{staticStyle:{"width":"120px"},attrs:{"disabled":_vm.currentQuestionIndex >= _vm.questions.length - 1 ||
                  _vm.answers.length <= _vm.currentQuestionIndex,"color":"primary"},on:{"click":function($event){return _vm.moveQuestion('next')}}},[_c('div',{staticClass:"body-1 white--text"},[_vm._v(_vm._s(_vm.$t('next')))]),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),(_vm.result)?_c('div',{staticClass:"mt-15"},[_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"secondary","outlined":""},on:{"click":_vm.beforeConfirmation}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" ")])],1):_vm._e()])]):_vm._e()])],1),((_vm.intro && _vm.indicatorNotes) || _vm.confirmResultMsg)?_c('Dialog',{attrs:{"dialog":_vm.indicatorNotes || _vm.confirmResultMsg,"sendResult":_vm.confirmResultMsg && _vm.confirmResult,"title":_vm.indicatorNotes ? 'notesBeforeIndicator' : 'warning',"closeTxt":_vm.indicatorNotes && 'closeTxt'},on:{"closeDialog":_vm.closeDialog}},[(_vm.indicatorNotes)?_c('div',[(_vm.intro.intro_video_link)?_c('iframe',{attrs:{"src":_vm.intro.intro_video_link,"width":"100%","height":"400px","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":""}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.intro[_vm.$root.lang + 'intro_info'])}})]):_c('div',[(_vm.confirmResultMsg && _vm.quizResult[_vm.lang + 'before_result'])?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.quizResult[_vm.lang + 'before_result'])}}),_c('v-divider',{staticClass:"my-4"})],1):_vm._e(),(_vm.confirmResultMsg && _vm.intro[_vm.$root.lang + 'end_info'])?_c('div',{domProps:{"innerHTML":_vm._s(_vm.intro[_vm.$root.lang + 'end_info'])}}):(_vm.confirmResultMsg)?_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.$t('indicatorConfirmMsg'))+" ")]):_vm._e()])]):_vm._e(),(_vm.moreAns && true)?_c('Dialog',{attrs:{"dialog":_vm.moreAns && true},on:{"closeDialog":_vm.closeDialog}},[_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.moreAns)}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }